
.auth__already_sign_in{
  cursor: pointer;
  color: blue;
}



.app_auth_message{
  // border: 1px solid #000;
  border-radius:10px;
  border-bottom-right-radius: 0px;
  position :fixed;
  bottom:70px;
  font-size: 20px;
  z-index:100;
  right:30px;
  padding:10px;
  box-shadow: -5px -5px 15px #000;
  background-color: rgb(155, 15, 225);
}
.app_auth_message_sub{
  border: 1px solid #ccc;
  border-radius:10px;
  border-bottom-right-radius: 0px;
  position :fixed;
  bottom:40px;
  z-index:100;
  right:30px;
  padding:10px;
  box-shadow: -5px -5px 15px #000;
  background-color: rgb(124, 85, 143);
}

.app_auth_message_sub_sub{
  border: 1px solid #ccc;
  border-radius:10px;
  border-bottom-right-radius: 0px;
  position :fixed;
  bottom:20px;
  z-index:100;
  right:30px;
  padding:5px;
  box-shadow: -5px -5px 15px #000;
  background-color: rgb(124, 85, 143);
}
.app_auth_message_hidden{
  visibility: hidden;
}

.app_auth_message_show{
  visibility: visible;
}

.app_auth_container{
  margin-left: 33.5%;
  display: flex;
  flex-direction: column;
  border:  1px solid #ccc;
  align-items: center;
  margin-top:30px;
  padding-top:20px;
  width:450px;
  height: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(78, 77, 77);
  

}

.app__auth{
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
}
  
.signUp{
  margin-top:20px;
}
  .app__auth-cards {
    // width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    // padding: 20px;
    flex-wrap: wrap;
    margin: .5rem .5rem 0rem;
  
    .app__auth-card {
      min-width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem .5rem;
      padding: 1rem;
  
      border-radius: 10px;
      cursor: pointer;
      background-color: #fef4f5;
  
      transition: all 0.3s ease-in-out;
  
      img {
        width: 30px;
        height: 30px;
        margin: 0 0.7rem;
      }
      p {
        font-weight: 600;
      }
  
      a {
        text-decoration: none;
        font-weight: 500;
      }
  
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .app__auth-cards .app__auth-card:last-child {
    background-color: #f2f7fb;
  }
  
  .app__auth-form {
    width: 65%;
    flex-direction: column;
    margin: 1rem 2rem;
  
    div {
      width: 100%;
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--primary-color);
      transition: all 0.3s ease-in-out;
  
      input
      {
        width: 100%;
        padding: 0.95rem;
        border: none;
        background-color: var(--primary-color);
        border-radius: 7px;
  
        font-family: var(--font-base);
        color: var(--secondary-color);
        outline: none;
      }
  
      
  
      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    button {
      border-radius: 10px;
      padding: 1rem 2rem;
      border: none;
      background-color: var(--secondary-color);
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 1rem 0;
      font-family: var(--font-base);
      cursor: pointer;
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }
  